<template>
    <fhiry-page class="home" :banners="homeData.banners">
        <fhiry-title
                class="info-item"
                v-if="homeData.content != void 0"
                :english="homeData.content.services.english"
                :chinese="homeData.content.services.chinese"
        >
            <div class="service-list-box item-box">
                <service-item
                        class="services-item"
                        v-for="(serv, i) in homeData.content.services.servicesList"
                        :key="i"
                        :data="serv"
                />
            </div>
            <more-button class="more-btn" @click.native="toLink(homeData.content.services.link)" />
        </fhiry-title>
        <fhiry-title
                class="info-item"
                v-if="homeData.content != void 0"
                :english="homeData.content.cooperationCase.english"
                :chinese="homeData.content.cooperationCase.chinese"
        >
            <div class="case-list-box item-box">
                <fhiry-case
                    class="case-item"
                    v-for="(caseItem, i) in homeData.content.cooperationCase.cases"
                    :key="i"
                    :data="caseItem"
                />
            </div>
            <more-button class="more-btn" @click.native="toLink(homeData.content.cooperationCase.link)" />
        </fhiry-title>
        <fhiry-title
                class="info-item industry"
                v-if="homeData.content != void 0"
                :english="homeData.content.industryDynamic.english"
                :chinese="homeData.content.industryDynamic.chinese"
        >
            <div class="item-box">
                <dynamic-box
                    :data="homeData.content.industryDynamic.dynamic"
                />
            </div>
            <more-button class="more-btn" @click.native="toLink(homeData.content.industryDynamic.link)" />
        </fhiry-title>
    </fhiry-page>
</template>

<script>
    // 首页
    import FhiryPage from '@/components/FhiryPage';
    import FhiryCase from '@/components/FhiryCase';
    import FhiryTitle from '@/components/FhiryTitle';
    import ServiceItem from './ServiceItem';
    import DynamicBox from './DynamicBox';
    import MoreButton from './MoreButton';
    import {
        getHomeData
    } from '@/api/request';

    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'home',
        created () {
            this.getHomeDataAjax();
        },
        data () {
            return {
                homeData: {}
            }
        },
        computed: {
            ...mapGetters([
                'selfNavs'
            ])
        },
        methods: {
            ...mapMutations([
                'setNav'
            ]),
            getHomeDataAjax () {
                getHomeData().then(res => {
                    this.homeData = res;
                })
            },
            toLink (link) {
                let nav = '';
                this.selfNavs.forEach(item => {
                    if (item.path == link) {
                        nav = item;
                    }
                });
                if (nav != '') {
                    this.setNav(nav);
                }
                this.$router.push({
                    path: link
                });
            }
        },
        components: {
            FhiryPage,
            FhiryTitle,
            ServiceItem,
            FhiryCase,
            DynamicBox,
            MoreButton
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .home {
        .info-item {
            padding: 80px 0;
            // 不显示行业动态
            &.industry {
                display: none;
            }
            &:first-child, &:nth-child(3) {
                background-color: #f7f7f7;
            }
            // 业务领域
            .item-box {
                width: @limit-width;
                margin: 0 auto 30px;
            }
            .service-list-box {
                display: flex;
                flex-wrap: wrap;
                font-size: 12px;
                .services-item {
                    margin-right: 20px;
                    margin-bottom: 16px;
                    width: calc(~'50% - 10px');
                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }
            .case-list-box {
                .case-item {
                    margin-bottom: 20px;
                    width: 100%;
                }
            }
        }
    }

    // 移动端
    @media screen and (max-width: 1023px) {
        .home {
            width: 100%;
            .info-item {
                padding: 0.5333rem 0;
                width: 100%;
                box-sizing: border-box;
                .item-box {
                    margin: 0 auto 0.2666rem;
                    padding: 0 0.2666rem;
                    width: 100%;
                    box-sizing: border-box;
                }
                .service-list-box {
                    flex-direction: column;
                    .services-item {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        .home {
            .info-item {
                padding: 0.5333rem 0;
                .item-box {
                    margin: 0 auto 0.2666rem;
                    padding: 0 0.2666rem;
                    width: 100%;
                }
                .service-list-box {
                    flex-direction: row;
                    .services-item {
                        width: calc(~'50% - 10px');
                    }
                }
            }
        }
    }
</style>