<template>
    <div class="service">
        <div class="img-box">
            <img :src="`${imgBaseURL}${data.icon}`" alt="">
        </div>
        <div class="services-list">
            <h4>{{ data.servicesItem }}</h4>
            <ul class="services-desc">
                <li v-for="(desc, i) in data.servicesDesc" :key="i">
                    {{ desc.text }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: '',
        props: {
            data: {
                type: Object,
                default () {
                    return {
                        icon: '',
                        servicesItem: '',
                        servicesDesc: []
                    }
                }
            }
        },
        computed: {
            ...mapState([
                'imgBaseURL'
            ])
        }
    }
</script>

<style lang="less" scoped>
    .service {
        display: flex;
        background-color: #FFF;
        box-shadow: 0 0 6px #bbb;
        padding: 20px 30px;
        .img-box {
            img {
                width: 70px;
                height: 70px;
            }
        }
        .services-list {
            margin-left: 40px;
            h4 {
                position: relative;
                left: -16px;
                margin-bottom: 6px;
                font-weight: bold;
                font-size: 18px;
            }
            .services-desc {
                list-style: disc;
                li {
                    color: #999;
                    font-size: 14px;
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .service {
            flex-direction: column;
            padding: 0.26666rem;
            .img-box {
                margin-bottom: 0.21333rem;
                text-align: center;
            }
            .services-list {
                h4 {
                    margin-bottom: 0.21333rem;
                    text-align: center;
                }
                .services-desc {
                    li {
                        line-height: 1.7;
                        font-size: 0.2666rem;
                    }
                }
            }
        }
    }
</style>