<template>
    <div class="dynamic">
        <div class="dynamic-img-box dynamic-box-item">
            <img :src="`${imgBaseURL}${collpaseDynamic.img}`" alt="">
            <div class="info">
                <h4>{{ collpaseDynamic.title }}</h4>
                <div class="time">{{ collpaseDynamic.time }}</div>
            </div>
        </div>
        <el-scrollbar class="dynamic-list dynamic-box-item">
            <el-collapse
                    v-model="collpase"
                    accordion
            >
                <el-collapse-item
                            v-for="(dynamic, i) in dynamicData"
                            :key="i"
                            :name="dynamic.id"
                >
                    <template slot="title">
                        <header class="dynamic-header">
                            <h1>{{ dynamic.title }}</h1>
                            <span class="time">{{ dynamic.time }}</span>
                        </header>
                    </template>
                    <p>{{ dynamic.desc }}</p>
                </el-collapse-item>
            </el-collapse>
        </el-scrollbar>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'dynamic-box',
        props: {
            data: {
                type: Array,
                default () {
                    return []
                }
            }
        },
        data () {
            return {
                collpase: '0'
            }
        },
        computed: {
            ...mapState([
                'imgBaseURL'
            ]),
            // 添加id, 设置选中项
            dynamicData () {
                return this.data.map((i, index) => {
                    return {
                        id: index + '',
                        ...i
                    }
                })
            },
            collpaseDynamic () {
                if (this.collpase == '') {
                    return this.dynamicData[0];
                }
                for (let i = 0; i < this.dynamicData.length; i++) {
                    if (this.dynamicData[i].id == this.collpase) {
                        return this.dynamicData[i];
                    }
                }
                return this.dynamicData[0];
            }
        }
    }
</script>

<style lang="less" scoped>
    .dynamic {
        display: flex;
        .dynamic-box-item {
            height: 337px;
            margin-right: 20px;
            width: calc(~'50% - 10px');
            &:last-child {
                margin: 0;
            }
        }
        .dynamic-img-box {
            position: relative;
            .info {
                position: absolute;
                bottom: 3px;
                z-index: 999;
                padding: 14px 16px;
                width: 100%;
                color: #FFF;
                background-color: rgba(0, 0, 0, 0.4);
                .time {
                    margin-top: 8px;
                    color: #ccc;
                    font-size: 10px;
                }
            }
        }
        .dynamic-list {
            .dynamic-header {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                width: 100%;
                margin-left: 23px;
                h1 {
                    width: 70%;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .time { 
                    font-size: 10px;
                }
            }
            p {
                padding: 0 10px;
                text-indent: 20px;
                color: #999;
                font-size: 10px;
            }
        }
    }
</style>

<style lang="less">
    .dynamic {
        .dynamic-list {
            .el-collapse {
                .el-collapse-item__header {
                    position: relative;
                    padding: 0 10px;
                    .el-collapse-item__arrow {
                        position: absolute;
                        top: 18px;
                        left: 10px;
                    }
                }
            }
        }
    }
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
</style>