<template>
    <div class="more-box">
        <button class="btn">查看更多</button>
    </div>
</template>

<script>
    export default {
        name: 'more-button'
    }
</script>

<style lang="less">
    @import '~@/assets/less/common.less';

    .more-box {
        text-align: center;
        .btn {
            padding: 6px 20px;
            color: @theme-color;
            border: 1px solid @theme-color;
            background-color: #FFF;
            font-size: 16px;
            cursor: pointer;
            &:hover {
                background-color: #f6f6f6;
            }
        }
    }
</style>